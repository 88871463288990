exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-previewer-tsx": () => import("./../../../src/pages/previewer.tsx" /* webpackChunkName: "component---src-pages-previewer-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-branch-tsx": () => import("./../../../src/templates/branch.tsx" /* webpackChunkName: "component---src-templates-branch-tsx" */),
  "component---src-templates-dynamic-page-tsx": () => import("./../../../src/templates/dynamic-page.tsx" /* webpackChunkName: "component---src-templates-dynamic-page-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-wellbeinghub-home-tsx": () => import("./../../../src/templates/wellbeinghub/home.tsx" /* webpackChunkName: "component---src-templates-wellbeinghub-home-tsx" */)
}

