/* eslint-env browser */
"use strict";

require("./src/styles/global.scss");
const ReactDOM = require("react-dom/client");

module.exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  // don't update scroll if only query params are changed
  if (
    prevRouterProps &&
    prevRouterProps.location.pathname === routerProps.location.pathname
  ) {
    return false;
  }
  // default behaviour
  return true;
};

module.exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
