module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unum","short_name":"Unum","start_url":"/","background_color":"#015294","theme_color":"#015294","display":"minimal-ui","icon":"src/images/favicons/icon-32x32.png","icons":[{"src":"images/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"images/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"images/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"images/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"images/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"images/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"images/favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"images/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f8c810868616716412d012c1def4393a"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MPQZJZ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-4432528-1","249923379"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"respectDNT":true,"head":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
